
import { defineComponent, reactive, ref } from 'vue';
import ModalChassis from './components/ModalChassis.vue'
import useEmitter from '@/composables/Emmiter';
import moment from "moment";
import useAlert from '@/composables/Alert';
import { ContentLoader } from 'vue-content-loader';
import ChartRoteiroEntrega from './components/graficos/ChartRoteiroEntrega.vue'
import ChartMediaChecklist from './components/graficos/ChartMediaChecklist.vue'
import ChartConformidadeChecklist from './components/graficos/ChartConformidadeChecklist.vue'
import ChartCadastroServicoConectado from './components/graficos/ChartCadastroServicoConectado.vue'
import ChartFiatConnect from './components/graficos/ChartFiatConnect.vue'
import ChartJeppAdventureIntelligence from './components/graficos/ChartJeppAdventureIntelligence.vue'
import { getHistoricoChecklistStellantis, getHistoricoChecklistStellantisPaginate } from '@/services/HistoricoChecklistStellantis'
import { Checklist } from "@/models/HistoricoChecklistStellantisModel";

export default defineComponent({
    name: "historico-checklist",
    components: {
        ContentLoader,
        ModalChassis,
        ChartRoteiroEntrega,
        ChartMediaChecklist,
        ChartConformidadeChecklist,
        ChartCadastroServicoConectado,
        ChartFiatConnect,
        ChartJeppAdventureIntelligence
    },
    setup() {
        const { showTimeAlert } = useAlert();
        const emitter = useEmitter();
        const dataHistorico = ref<Checklist[] | []>([]);
        const loadingTable = ref(true);
        const itemSelected: any = ref({});
        const codConcessionaria = ref(null);
        const postData = ref<{
            chassi: string,
            codConsultor: string,
            setor: string[]
            data: string
        }>();
        const infoCount = reactive({
            totalChecklist: 0,
            andamento: 0,
            finalizado: 0,
            totalChassi: 0
        });
        const historicoContatoPeriodo = ref("");
        const dataFinal = ref("");
        const dataInicial = ref("");
        const selectChassi = ref('')
        const reloadChart = ref(false)
        const currentPage = ref(1)

        emitter.on("filtrar-historico-checklist", (filtroInfo) => {
            codConcessionaria.value = filtroInfo.codConcessionaria;

            if (filtroInfo.postData) {
                const postDataOptions = {
                    data: filtroInfo.postData.data,
                    codConsultor: filtroInfo.postData?.codConsultor,
                    chassi: filtroInfo.postData?.chassi,
                    setor: filtroInfo.postData?.setor
                };

                postData.value = postDataOptions;
            }
            trataNomePeriodo(filtroInfo.dataSelecionada);
            currentPage.value = 1
            listaHistoricoChecklist();
        });

        emitter.on('update-historico-checklist' , () => {
            currentPage.value = 1
            listaHistoricoChecklist();
        })

        const selectedChassi = (checklist) => {
            itemSelected.value = checklist
        }

        function trataNomePeriodo(filtroData) {
            if (!filtroData) {
                historicoContatoPeriodo.value = "";
                return;
            }

            dataInicial.value = filtroData.dataInicial.format("YYYY-MM-DD");
            dataFinal.value = filtroData.dataFinal.format("YYYY-MM-DD");;

            const auxDataInicial = filtroData.dataInicial;
            const auxDataFinal = filtroData.dataFinal;

            if (auxDataInicial.isSame(auxDataFinal, "day") && moment().isSame(auxDataInicial, "day")) {
                historicoContatoPeriodo.value = `hoje`;
            } else if (auxDataInicial.isSame(auxDataFinal, "day")) {
                historicoContatoPeriodo.value = `${auxDataInicial.locale("pt-br").format('DD MMMM - YYYY').replaceAll("-", "de")}`;
            } else {
                historicoContatoPeriodo.value = `${auxDataInicial.locale("pt-br").format('DD MMMM - YYYY').replaceAll("-", "de")} até ${auxDataFinal.locale("pt-br").format('DD MMMM - YYYY').replaceAll("-", "de")}`;
            }
        }

        async function listaHistoricoChecklist() {
            loadingTable.value = true;

            try {
                reloadChart.value = false

                if (postData.value) {

                    const { paginate, totalChecklist, totalChecklistFinalizado, totalChecklistPendente } = await getHistoricoChecklistStellantis(
                        codConcessionaria.value,
                        postData.value
                    )

                    const paginateData = paginate
                    dataHistorico.value = paginateData.data

                    infoCount.andamento = totalChecklistPendente
                    infoCount.totalChecklist = totalChecklist
                    infoCount.finalizado = totalChecklistFinalizado
                    infoCount.totalChassi = paginate.total

                    reloadChart.value = true
                }

            } catch (error) {
                showTimeAlert("Não foi possível completar a solicitação", "error");
            } finally {
                loadingTable.value = false;
            }
        };

        const handleChassi = (chassi: string) => {
            selectChassi.value = chassi
        }

        const pagination = async (pageSelected) => {
            currentPage.value = pageSelected
            const targetDiv = document.getElementById('targetDiv');

            if (targetDiv) {
                targetDiv.scrollIntoView({ behavior: 'smooth' });
            }

            try {
                loadingTable.value = true

                if (postData.value) {

                    const { paginate } = await getHistoricoChecklistStellantisPaginate(`historicochecklist/stellantis/${codConcessionaria.value}?page=${pageSelected}`,
                        postData.value
                    )
                    dataHistorico.value = paginate.data
                }

            } catch (error) {
                showTimeAlert("Não foi possível completar a solicitação", "error");
            } finally {
                loadingTable.value = false
            }
        };

        return {
            loadingTable,
            dataHistorico,
            listaHistoricoChecklist,
            itemSelected,
            historicoContatoPeriodo,
            infoCount,
            codConcessionaria,
            dataInicial,
            dataFinal,
            handleChassi,
            selectChassi,
            selectedChassi,
            reloadChart,
            currentPage,
            pagination
        }
    },
})
