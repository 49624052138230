import {GetCadastroServicoConcectado, GetConformidadeChecklist, GetFiatConnect, GetJeppAdventureIntelligence, GetMediaChecklist, GetPerguntaSemConformidade, GetTipoChecklistChecklist } from "@/models/ChartStellantis";
import ApiService from "./ApiService";

const tipoChecklist = async (codConcessionaria : Number | string,payload) : Promise<GetTipoChecklistChecklist> => {
    const { data } = await ApiService.post(`stellantis/kpi/tipo-checklist/${codConcessionaria}`,payload);
    return data;
};

const mediaTempoChecklist = async (codConcessionaria : Number | string,payload) : Promise<GetMediaChecklist> => {
    const { data } = await ApiService.post(`stellantis/kpi/tempo-medio/${codConcessionaria}`,payload);
    return data;
};

const conformidadeChecklist = async (codConcessionaria : Number | string,payload) : Promise<GetConformidadeChecklist> => {
    const { data } = await ApiService.post(`stellantis/kpi/conformidade/${codConcessionaria}`,payload);
    return data;
};

const perguntaSemconformidadeChecklist = async (codConcessionaria : Number | string,payload) : Promise<GetPerguntaSemConformidade[]>  => {
    const { data } = await ApiService.post(`stellantis/kpi/pergunta-conformidade/${codConcessionaria}`,payload);
    return data;
};

const cadastroServicoConectado = async (codConcessionaria : Number | string,payload) : Promise<GetCadastroServicoConcectado>  => {
    const { data } = await ApiService.post(`stellantis/kpi/cadastro-servico-conectado/${codConcessionaria}`,payload);
    return data;
};

const fiatConnect = async (codConcessionaria : Number | string,payload) : Promise<GetFiatConnect>  => {
    const { data } = await ApiService.post(`stellantis/kpi/fiat-connect/${codConcessionaria}`,payload);
    return data;
};

const jeppAdventureIntelligence = async (codConcessionaria : Number | string,payload) : Promise<GetJeppAdventureIntelligence>  => {
    const { data } = await ApiService.post(`stellantis/kpi/jepp-adventure-intelligence/${codConcessionaria}`,payload);
    return data;
};

export {
    tipoChecklist,
    mediaTempoChecklist,
    conformidadeChecklist,
    perguntaSemconformidadeChecklist,
    cadastroServicoConectado,
    fiatConnect,
    jeppAdventureIntelligence
}
