
import store from "@/store/index";
import useAlert from '@/composables/Alert';
import MessageModal from "@/components/modals/geral/MessageModal.vue";
import LogEtapasModal from "@/components/modals/OficinaDigitalModals/LogEtapasModal.vue";
import { excluirChecklist } from "@/services/HistoricoChecklistService";
import { onMounted, ref, watch } from '@vue/runtime-core';
import { hideModal } from '@/core/helpers/dom';
import AuthService from "@/services/AuthService";
import useEmitter from '@/composables/Emmiter';

export default {
    name: "historico-checklist-vermais-modal",
    props: {
        id: String,
        info: Object,
        referenciaStatusChecklist: Object,
        hiddenButtons: {
            type: Boolean,
            default: false
        }
    },
    components: {
        MessageModal,
        LogEtapasModal
    },
    setup(props, { emit }) {
        const { showTimeAlert } = useAlert();
        const dominio_orcamento = store.getters.layoutConfig("distribuidor.dominio_orcamento");
        const modal: any = ref({});
        const logEtapasInfo: any = ref({});
        const motivoModel: any = ref("");
        const userInfo = AuthService.getUsuarioAuth();
        const codNivel = userInfo.codNivel;
        const modalAnexos = ref()
        const emitter = useEmitter()

        onMounted(async () => {
            const refModal = document.getElementById(`modal-${props.id}`);
            modal.value = refModal;
        });

        function atualizaLogEtapas() {
            if (props.info) {
                if (props.info.codCheckList != logEtapasInfo.value.codChecklist) {
                    logEtapasInfo.value = { ...props.info, codChecklist: props.info.codCheckList }
                }
            }
        }

        async function excluir() {
            try {
                await excluirChecklist(props.info?.codConcessionaria, props.info?.checklist, props.info?.codCheckList, { motivo: motivoModel.value });
                showTimeAlert("O checklist foi excluído com sucesso!");
                const elementModal = document.getElementById(`excluir-${props.id}`);
                hideModal(elementModal);
                hideModal(modal.value);
                emitter.emit("update-historico-checklist", {})
            } catch (e) {
                showTimeAlert("Não foi possível completar a solicitação, entre em contato com o nosso suporte!", "error");
            }
        }

        return {
            codNivel,
            dominio_orcamento,
            motivoModel,
            excluir,
            logEtapasInfo,
            atualizaLogEtapas,
            modalAnexos,
        }
    }
};
